const storeValues = (campaigns, users) => {
  localStorage.setItem('campaigns', JSON.stringify(campaigns));
  localStorage.setItem('users', JSON.stringify(users));
};

const storeHourlyRate = (hourlyRate) => {
  localStorage.setItem('hourlyRate', hourlyRate);
};

const getValuesFromStorage = () => {
  const campaigns = JSON.parse(localStorage.getItem('campaigns'));
  const users = JSON.parse(localStorage.getItem('users'));
  return {
    campaigns,
    users
  };
};

const getHourlyRateFromStorage = () => {
  const hourlyRate = localStorage.getItem('hourlyRate');
  return hourlyRate;
};

export { storeValues, storeHourlyRate, getValuesFromStorage, getHourlyRateFromStorage };
