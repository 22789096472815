import { useState, useEffect } from 'react';
import { Stack, Button, Input, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { getHourlyRateFromStorage, storeHourlyRate, storeValues } from '../utils/store';

const IncomeForm = ({ onSubmit }) => {
  const [hourlyRate, setHourlyRate] = useState('');
  const [clearValuesOnSubmit, setClearValuesOnSubmit] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Check and load hourly rate from local storage
  useEffect(() => {
    const storedHourlyRate = getHourlyRateFromStorage();
    if (storedHourlyRate) {
      setHourlyRate(parseFloat(storedHourlyRate));
    }
  }, []);

  const clearValues = () => {
    storeValues({}, {});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (clearValuesOnSubmit) {
      clearValues();
    }
    storeHourlyRate(hourlyRate);
    onSubmit(selectedFile, hourlyRate);
  };

  const handleFileUpload = (file) => {
    if (file) {
      setSelectedFile(file);
    } else {
      console.error('Error uploading file');
      setSelectedFile(null);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} mt={4} alignItems="flex-start">
        <TextField
          id="hourlyRate"
          label="Common hourly rate"
          variant="outlined"
          type="number"
          onChange={(event) => setHourlyRate(event.target.value)}
          value={hourlyRate}
          required
        />
        <Stack direction="row" spacing={2} alignItems="center">
          <label htmlFor="fileupload">
            <Input
              id="fileupload"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(event) => handleFileUpload(event.target.files[0])}
              sx={{
                display: 'none'
              }}
              required
            />
            <Button variant="contained" component="span">
              Select Excel file
            </Button>
          </label>
          <div aria-live="polite">
            {selectedFile && <Typography>{selectedFile.name}</Typography>}
          </div>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button type="submit" variant="contained" color="success" disabled={!selectedFile}>
            Calculate
          </Button>
          <FormControlLabel
            control={<Checkbox onChange={(event) => setClearValuesOnSubmit(event.target.checked)} />}
            label="Reset storage"
          />
        </Stack>
      </Stack>
    </form>
  );
};

export default IncomeForm;
