import { useState } from 'react';
import xlsxParser from 'xlsx-parse-json';
import IncomeForm from './components/IncomeForm';
import DataTable from './components/DataTable';
import { Container, Alert, LinearProgress, CircularProgress, Stack } from '@mui/material';
import { exportToCsv } from './utils/csvExporter';

const formatTime = (hours, mins) => {
  const minutesToHours = Math.floor(Number(mins) / 60);
  const newHours = (Number(hours) + minutesToHours).toString();
  const newMins = (mins - minutesToHours * 60).toString();
  return [
    `${newHours.length < 2 ? `0${newHours}` : newHours}`,
    `${newMins.length < 2 ? `0${newMins}` : newMins}`
  ].join(':');
};

/**
 * Formats an object of employees with name and campaigns
 * @param {Object[]} data
 * @returns {Object} Formatted object with usernames as keys and user objects as values
 */
const formatData = (data) => {
  return data
    .filter((data) => data.Aktiviteetti === 'Myyntityö' && data.Aika !== '00:00')
    .reduce((prev, curr) => {
      if (!prev[curr['Käyttäjätunnus']]) {
        // If user doesn't exist create a new user with the campaign and provisio
        prev[curr['Käyttäjätunnus']] = {
          name: curr['Työntekijän nimi'],
          employeeNumber: curr['Käyttäjätunnus'],
          campaigns: {
            [curr.Kampanja]: {
              hours: curr.Aika,
              sales: parseFloat(curr.Euromyynti),
              provisio: curr.Provisio ? parseFloat(curr.Provisio) : 0,
            }
          }
        };
      } else if (!prev[curr['Käyttäjätunnus']].campaigns[curr.Kampanja]) {
        // If user already exists append campaign to the campaigns-list for the user and add provisio
        prev[curr['Käyttäjätunnus']].campaigns[curr.Kampanja] = {
          hours: curr.Aika,
          sales: parseFloat(curr.Euromyynti),
          provisio: curr.Provisio ? parseFloat(curr.Provisio) : 0
        };
      } else {
        // If campaign exists for user append hours and sales and provisio
        prev[curr['Käyttäjätunnus']].campaigns[curr.Kampanja].provisio += curr.Provisio ? parseFloat(curr.Provisio) : 0;
        prev[curr['Käyttäjätunnus']].campaigns[curr.Kampanja].sales += parseFloat(curr.Euromyynti);
        const prevTime = prev[curr['Käyttäjätunnus']].campaigns[curr.Kampanja].hours;
        const [prevHours, prevMins] = prevTime.split(':');
        const [currHours, currMins] = curr.Aika.split(':');
        const newHours = Number(prevHours) + Number(currHours);
        const newMins = Number(prevMins) + Number(currMins);
        prev[curr['Käyttäjätunnus']].campaigns[curr.Kampanja].hours = formatTime(newHours, newMins);
      }
      return prev;
    }, {});
};

const App = () => {
  const [formattedData, setFormattedData] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [hourlyRate, setHourlyRate] = useState('');
  const [exported, setExported] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (file, hourlyRate) => {
    setLoading(true);
    setExported(false);
    setHourlyRate(hourlyRate);
    xlsxParser.onFileSelection(file).then((dataSet) => {
      const formattedData = formatData(dataSet.VKTT);
      setFormattedData(formattedData);
      setCurrentData(dataSet.VKTT.filter((row) => row.Aika !== '00:00'));
      setLoading(false);
    });
  };

  const handleExport = (campaigns, users, qualitySalaries, provisionSalaries) => {
    exportToCsv(hourlyRate, currentData, campaigns, users, qualitySalaries, provisionSalaries);
    setExported(true);
  };
  return (
    <Container sx={{ margin: '1rem auto' }}>
      <LinearProgress
        variant="determinate"
        value={exported ? 100 : currentData ? 50 : 0}
        color={exported ? 'success' : 'primary'}
      />
      <IncomeForm onSubmit={handleSubmit} />
      <div aria-live="polite">
        {loading ? (
          <Stack alignItems="center" justifyContent="center" sx={{ height: '40vh' }}>
            <CircularProgress id="loading-indicator" />
          </Stack>
        ) : (
          <div aria-describedby="loading-indicator" aria-busy={loading}>
            {formattedData && !exported && (
              <DataTable data={formattedData} hourlyRate={hourlyRate} onExport={handleExport} />
            )}
            {exported && (
              <Alert severity="success" sx={{ marginTop: '2rem' }}>
                File exported successfully!
              </Alert>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default App;
