import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Box,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { getValuesFromStorage, storeValues } from '../utils/store';
import { convertTimeToFloat, countQualitySalary, countQualitySalaryPerHour } from '../utils/helpers';

const DataTable = ({ data, hourlyRate, onExport }) => {
  const [users, setUsers] = useState({});
  const [campaigns, setCampaigns] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);

  // Check and load employee rates and campaign rates from local storage
  useEffect(() => {
    const values = getValuesFromStorage();
    setUsers(values.users || {});
    setCampaigns(values.campaigns || {});
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    storeValues(campaigns, users);
    let usersQualitySalaries = {}
    let usersProvisionSalaries = {}
    Object.keys(users).forEach((user) => {
      if (data[user]) {
        let userQualitySalaries = {};
        let userProvisionSalaries = {};
        Object.keys(data[user].campaigns).forEach((campaign, index) => {
          if (data[user].campaigns[campaign].provisio > 0) {
            userProvisionSalaries[campaign] = data[user].campaigns[campaign].provisio;
          }
          userQualitySalaries[campaign] = countQualitySalaryPerHour(
            countQualitySalary(
              data[user].campaigns[campaign].sales,
              data[user].campaigns[campaign].hours,
              campaigns[campaign],
              users[user] && users[user].employeeRate ? users[user].employeeRate : undefined,
              hourlyRate
            ),
            data[user].campaigns[campaign].hours
          )
          if (!userQualitySalaries['hours']) userQualitySalaries['hours'] = {};
          userQualitySalaries['hours'][campaign] = convertTimeToFloat(data[user].campaigns[campaign].hours);
        })
        usersQualitySalaries[user] = userQualitySalaries;
        usersProvisionSalaries[user] = userProvisionSalaries;
        console.log('data user', data[user])
      }
    })
    onExport(campaigns, users, usersQualitySalaries, usersProvisionSalaries);
  };

  // All rate values must be between 0 and 1(e.g. 50% = 0.5)
  const isFieldValid = (value) => {
    return value && value >= 0 && value <= 1;
  };

  const countQualitySalaryForUser = (user) => {
  return (Object.keys(data[user].campaigns).map((campaign, index) => {
      return (
      <div key={index}>
        {countQualitySalaryPerHour(
          countQualitySalary(
            data[user].campaigns[campaign].sales,
            data[user].campaigns[campaign].hours,
            campaigns[campaign],
            users[user] && users[user].employeeRate ? users[user].employeeRate : undefined,
            hourlyRate
          ),
          data[user].campaigns[campaign].hours
        )}
      </div>)}
    ))}

  const CampaignCells = ({ user, selected }) => {
    return (
      <>
        <TableCell>
          {Object.keys(data[user].campaigns).map((campaign, index) => (
            <div key={index}>{campaign}</div>
          ))}
        </TableCell>
        <TableCell>
          {Object.keys(data[user].campaigns).map((campaign, index) => (
            <div key={index}>
              <TextField
                sx={{
                  maxWidth: '4rem',
                  display: selected ? 'flex' : 'none'
                }}
                inputProps={{
                  min: 0,
                  max: 1,
                  step: 0.0001
                }}
                aria-label="Campaign rate"
                id="campaignRate"
                variant="standard"
                type="number"
                value={campaigns[campaign]}
                onChange={(event) => {
                  const { value } = event.target;
                  setCampaigns({
                    ...campaigns,
                    [campaign]: value
                  });
                }}
                error={!isFieldValid(campaigns[campaign])}
                required
              />
              {!selected && (
                <Box
                  sx={{
                    background: isFieldValid(campaigns[campaign]) ? 'inherit' : '#ffe3e7',
                    minWidth: '1.5rem',
                    minHeight: '1.5rem',
                    textAlign: 'center'
                  }}
                >
                  {campaigns[campaign]}
                </Box>
              )}
            </div>
          ))}
        </TableCell>
        <TableCell>
          {Object.keys(data[user].campaigns).map((campaign, index) => (
            <div key={index}>{data[user].campaigns[campaign].hours}h</div>
          ))}
        </TableCell>
        <TableCell>
          {Object.keys(data[user].campaigns).map((campaign, index) => (
            <div key={index}>{data[user].campaigns[campaign].sales}€</div>
          ))}
        </TableCell>
        <TableCell>
          {countQualitySalaryForUser(user)}
        </TableCell>
        <TableCell>
          {Object.keys(data[user].campaigns).map((campaign, index) => (
            <div key={index}>{data[user].campaigns[campaign].provisio}€</div>
          ))}
        </TableCell>
      </>
    );
  };
  console.log(data);
  return (
    <form onSubmit={handleSubmit}>
      <TableContainer
        component={Paper}
        sx={{
          margin: '2rem 0'
        }}
      >
        <Table size="small" aria-label="Income data table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Employee number</TableCell>
              <TableCell align="right">Personal Rate</TableCell>
              <TableCell>Campaign</TableCell>
              <TableCell align="right">Campaign Rate</TableCell>
              <TableCell>Hours</TableCell>
              <TableCell>Sales</TableCell>
              <TableCell>Quality salary</TableCell>
              <TableCell>Provision</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Export</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data).map((user, index) => {
              const selected = selectedRow === user;
              return (
                <TableRow
                  key={index}
                  onClick={() => setSelectedRow(user)}
                  aria-label={`Edit row: ${data[user].name}`}
                  sx={{
                    background: selected ? '#f5f9ff' : 'white',
                    cursor: 'pointer',
                    '&:hover': {
                      background: 'rgba(25, 118, 210, 0.04)'
                    }
                  }}
                >
                  <TableCell>{data[user].name}</TableCell>
                  <TableCell>
                    <TextField
                      sx={{
                        maxWidth: '4rem',
                        display: selected ? 'flex' : 'none'
                      }}
                      aria-label="Employee number"
                      id="employeeNumber"
                      name="employeeNumber"
                      variant="standard"
                      type="string"
                      value={users[user] && users[user].employeeNumber ? users[user].employeeNumber : ''}
                      onChange={(event) => {
                        const { value } = event.target;
                        if (users[user]) {
                          setUsers({
                            ...users,
                            [user]: {
                              ...users[user],
                              employeeNumber: value
                            }
                          });
                        } else {
                          setUsers({
                            ...users,
                            [user]: {
                              employeeNumber: value
                            }
                          });
                        }
                      }}
                      required
                    />
                    {!selected && (
                      <Box
                        sx={{
                          background: users[user] && users[user].employeeNumber ? 'white' : '#ffe3e7',
                          minWidth: '1.5rem',
                          minHeight: '1.5rem',
                          textAlign: 'center'
                        }}
                      >
                        {users[user] && users[user].employeeNumber ? users[user].employeeNumber : ''}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell sx={{ maxWidth: '5rem' }} align="right">
                    <TextField
                      sx={{
                        maxWidth: '4rem',
                        display: selected ? 'flex' : 'none'
                      }}
                      inputProps={{
                        min: 0,
                        max: 1,
                        step: 0.0001
                      }}
                      aria-label="Employee rate"
                      id="employeeRate"
                      name="employeeRate"
                      variant="standard"
                      type="number"
                      value={users[user] && users[user].employeeRate ? users[user].employeeRate : undefined}
                      onChange={(event) => {
                        const { value } = event.target;
                        if (users[user]) {
                          setUsers({
                            ...users,
                            [user]: {
                              ...users[user],
                              employeeRate: value
                            }
                          });
                        } else {
                          setUsers({
                            ...users,
                            [user]: {
                              employeeRate: value
                            }
                          });
                        }
                      }}
                      error={!isFieldValid( users[user] && users[user].employeeRate ? users[user].employeeRate : undefined)}
                      required
                    />
                    {!selected && (
                      <Box
                        sx={{
                          background: isFieldValid(users[user] && users[user].employeeRate ? users[user].employeeRate : undefined) ? 'white' : '#ffe3e7',
                          minWidth: '1.5rem',
                          minHeight: '1.5rem',
                          textAlign: 'center'
                        }}
                      >
                        {users[user] && users[user].employeeRate ? users[user].employeeRate : undefined}
                      </Box>
                    )}
                  </TableCell>
                  <CampaignCells user={user} selected={selected} />
                  <TableCell>{data[user].provisio}</TableCell>
                  <TableCell align="right">
                    {selected && (
                      <IconButton
                        color="success"
                        aria-label={`Submit changes to row: ${data[user].name}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          setSelectedRow(null);
                        }}
                      >
                        <CheckIcon />
                      </IconButton>
                    )}
                    {selectedRow === null && (
                      <IconButton
                        color="primary"
                        aria-label={`Edit row: ${data[user].name}`}
                        onClick={() => setSelectedRow(user)}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color={'success'}
                      aria-label={`Export row: ${data[user].name}`}
                      onClick={() => users[user]}
                    >
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button type="submit" variant="contained" color="success">
        Export
      </Button>
    </form>
  );
};

export default DataTable;
