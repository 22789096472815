/**
 * Convert time (05:45) to hours float (5.75)
 * @param {string} time - Time in format hh:mm
 * @returns {number} float value of hours
 */
export const convertTimeToFloat = (time) => {
  let total = 0;
  const [hours, mins] = time.split(':');
  total = Number(hours);
  total += Number(mins) / 60;
  return total;
};

/**
 * Convert time (5.75) to hours and minutes splitted by : (05:45)
 * @param {number} time - Time in float format
 * @returns {string} time in format hh:mm
 */
export const convertTimeToSplitted = (time) => {
  const hours = Math.floor(time);
  const minutes = Math.round((time - hours) * 60);
  return `${hours}:${minutes}`;
};

/**
 * Urakkaprovisio ennen maksuastetta (a * b)
 * @param {number} sales - Tilaukset (€)
 * @param {number} commissionRate - Urakkaprovisio (esim. 6,30 % = 0.063)
 * @returns {number} Urakkaprovisio
 */
export const countCommission = (sales, commissionRate) => {
  return Number(sales) * parseFloat(commissionRate);
};

/**
 * Urakkapalkka (a * b)
 * @param {number} commission - Urakkaprovisio (€)
 * @param {number} personalRate - Myyjäkohtainen maksuaste (esim. 93,50 % = 0.935)
 * @returns {number} Urakkapalkka
 */
export const countContractSalary = (commission, personalRate) => {
  return parseFloat(commission) * parseFloat(personalRate);
};

/**
 * Tuntipalkka (a * b)
 * @param {number} hours - Työtunnit
 * @param {number} hourlyRate - Laatupalkkalaskennassa käytettävä tuntipalkka €/h
 * @returns {number} Tuntipalkka
 */
export const countHourlySalary = (hours, hourlyRate) => {
  return parseFloat(hours) * parseFloat(hourlyRate);
};

/**
 * Laatupalkka
 * @param {number} sales - Tilaukset (€)
 * @param {string} hours - Työtunnit (hh:mm)
 * @param {number} commissionRate - Urakkaprovisio (esim. 6,30 % = 0.063)
 * @param {number} personalRate - Myyjäkohtainen maksuaste (esim. 93,50 % = 0.935)
 * @param {number} hourlyRate - Laatupalkkalaskennassa käytettävä tuntipalkka €/h
 * @returns {number} Laatupalkka
 */
export const countQualitySalary = (sales, hours, commissionRate, personalRate, hourlyRate) => {
  const workTime = convertTimeToFloat(hours);
  const commission = countCommission(sales, commissionRate);
  const contractSalary = countContractSalary(commission, personalRate);
  const hourlySalary = countHourlySalary(workTime, hourlyRate);
  const qualitySalary = contractSalary - hourlySalary;
  return qualitySalary > 0 ? qualitySalary : 0;
};

/**
 * Laatupalkka per tunti
 * @param {number} qualitySalary - Laatupalkka
 * @param {string} hours - Työtunnit (hh:mm)
 * @returns {number} Laatupalkka / h
 */
export const countQualitySalaryPerHour = (qualitySalary, hours) => {
  const workTime = convertTimeToFloat(hours);
  return qualitySalary / workTime;
};
